import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import axios from "axios";
// import { Card, Typography, Table, Button, Modal, Popconfirm, Badge, Form } from "antd";
import { Button, Card, Input, Table, Form, Modal, Typography, Select, Checkbox , Switch , Collapse } from "antd";
import BASE_URL from "../../../config.js";
import { EditOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import '../../../assets/css/commande.scss';
import { Utils } from "../../../services/Utils.js";
const { Title } = Typography;

const CommandeProd = (props) => {
  const [commande, setCommande] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [modal2Open, setModal2Open] = useState(false);
  const [statusR, setStatusR] = useState(10);
  const [modeR, setModeR] = useState("all");
  const [role, setRole] = useState(localStorage.getItem("label") || null);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    getCommande();
    getStatus();
  }, []); 

  const detailCommande = (record) =>{
    setIdClicked(record.id); 
    setModal2Open(true);
  }

  const changeMode = (value) =>{
    setModeR(value);
    getCommande({type:value})
  }

  const changeStatus = (value) =>{
    if(value=="all"){
      setStatusR(null);
      getCommande()
    }else{
      setStatusR(value);
      getCommande({status:value})
    }
    
  }

  const getCommande = (filtre={}) => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    let fil = "?in_production=1";
    if(filtre?.status){
      if(modeR!=null){
        fil = `?status_id=${filtre?.status}&type_payment=${modeR}`;
      }
    }
    if(filtre?.type){
      if(statusR!=null){
        fil = `?status_id=${statusR}&type_payment=${filtre?.type}`;
      }else{
        fil = `?type_payment=${filtre?.type}`;
      }
    }
    axios
      .get(`${BASE_URL}/api/v1/command/list${fil}`
    ,{ headers })
      .then((res) => {
        const commandeData = res.data?.data;
        console.log("comma=>",commandeData)
        setCommande(commandeData);
        // setCommande(mook);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
      });
  };

  const columnsD = [
    {
      title: 'Product name',
      dataIndex: 'catalogue_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Quantity',
      align:"right",
      dataIndex: 'quantity'
    },
    {
      title: 'Price',
      dataIndex: 'prix_ligne',
      align:"right",
      render: (text) => <p style={{marginBottom:'0'}}>${text}</p>,
    },
  ];
  
  const dataD = [
    {
      key: '1',
      name: 'TEST BE',
      quantity: 10,
      price: 2000,
    },
    {
      key: '2',
      name: 'TEE-SHORT',
      quantity: 1,
      price: 4000,
    },
    {
      key: '3',
      name: 'TEE-SHORT BE',
      quantity: 500,
      price: 4000,
    },
  ];

  const getTotal = (allProduct) =>{
    var total = 0;
    allProduct.map((item)=>{
      total+=parseFloat(item.prix_ligne);
    })
    return total;
  }
  const columns = [
    {
      title: "N° order",
      dataIndex: [],
      align:"left",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text,record) => {
        return (
          <>
            <p style={{marginBottom:'-5px',fontWeight:'bold'}}>
              CM{record.number}
            </p>
            <span style={{marginBottom:'0',fontSize:'12px',color:'#8c8c8c'}}>
              {record.date_commande}
            </span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: [],
      align:"center",
      render: (text,record) => {
        return (
          <>
            <Button
                onClick={() => detailCommande(record)}
                type="primary"
                block
              >
                Order detail
              </Button>
              { record.id === idClicked &&(
                <Modal
                  title="Order detail"
                  centered
                  open={modal2Open}
                  onOk={() => setModal2Open(false)}
                  onCancel={() => setModal2Open(false)}
                  footer={[]}
                >
                  <Table
                    columns={columnsD}
                    dataSource={record.command_lines}
                    bordered
                    pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                    // title={() => 'Header'}
                    footer={() => 'TOTAL : '+Utils.formatMoney(parseFloat(getTotal(record.command_lines)))}
                  />
                </Modal>
              )
            }
            <div className="dot">
              {
                record.command_lines.map((item,i)=>{
                  if(i<8){
                    return (
                      <div title={item.status.label} className="dot-item" style={
                        {
                          backgroundColor:item.status.code
                        }
                      }>
                      
                      </div>
                    )
                  }
                })
              }
              
              {/* <div className="dot-item" style={{backgroundColor:'red'}}>
              
              </div>
              <div className="dot-item" style={{backgroundColor:'red'}}>
              
              </div>
              <div className="dot-item" style={{backgroundColor:'red'}}>
              
              </div>
              <div className="dot-item" style={{backgroundColor:'red'}}>
              
              </div>
              <div className="dot-item" style={{backgroundColor:'red'}}> */}
                {/* <span style={{fontSize:'11px',color:'white',display:'table-cell',fontWeight:'bold'}}>+5</span> */}
              {/* </div> */}
            </div>  
          </>
        );
      },
    },
    {
      title: "Sale's rep.",
      dataIndex: "client.name",
      align:"center",
      render: (text,record) => {
        return (
          <span>
            {record.command_owner.name}
          </span>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: [],
      align:"center",
      render: (text,record) => {
        return (
          <span>
            {record.command_owner.email}
          </span>
        );
      },
    },
    {
      title: "Left to pay",
      dataIndex: [],
      align:"right",
      className:"tdColumn",
      render: (text,record) => {
        return (
          <span>
            {Utils.formatMoney(parseFloat(record.prix_remise)-parseFloat(record.acompte))}
          </span>
        );
      },
    },
    {
      title: props.t("actions"),
      dataIndex: "id",
      align:"center",
      render: (text) => {
        return (
          <>
            {
              // (role === "1" || role === "2") ?(
              //   <Link
              //     to={{
              //       pathname: `/dashboard/commande/${text}`,
              //       state: { modal: true },
              //     }}
              //   > 
              //     <EditOutlined />
              //   </Link>
              // ):(
                <Link
                  to={{
                    pathname: `/dashboard/commande/show/${text}`,
                    state: { modal: true },
                  }}
                > 
                  <EyeOutlined />
                </Link>
              // )
            }
            
            {/* <span className="p-2"></span> */}
            
            
          </>
        );
      },
    },
  ];

  const getStatus = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
    };
    try {
        axios.get(`${BASE_URL}/api/v1/status-list`, { headers })
        .then((res)=>{
        if(res.data.success){
            const statu = res.data?.data;
            var temp = [];
            statu.map((item)=>{
            temp.push({value:item.id,label:item.label})
            })
            setStatus([...temp]);
        }
        })
        .catch(err => {
        console.log(err)
        })
    } catch (error) {
        console.log(error)
    }
    };

  return (
    <Card 
      className="commande-list"
        title={<Title level={3}>Production</Title>}
    >
      <div style={{ overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={commande}
            bordered
            rowKey="rowAction"
            pagination={{ hideOnSinglePage: true, pageSize: 15 }}
            loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
        />
      </div>
    </Card>
  );
};

export default withTranslation()(withRouter(CommandeProd));
