import React from "react";
import { Col, Row, Button, Space, message } from "antd";
import BASE_URL from "../../config";
import { Element, Link } from "react-scroll";
import Axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";

const styleCol1 = {
  background: "white",
  margin: "3px",
  minHeight: 42.5,
  lineHeight: "42.5px",
  textAlign: "center",
};
const styleCol2 = {
  margin: "3px",
  minHeight: 42.5,
  lineHeight: "42.5px",
  textAlign: "center",
};
const block3 = {
  display: "flex",

  margin: "3px 0px 3px 0px",
  minHeight: 42.5,
  lineHeight: "42.5px",
  textAlign: "center",
};
const carres = {
  width: "100%",
  margin: "0px 2px 0px 1px",
  backgroundColor: "white",
  maxWidth: "100px",
};
const styleInput = {
  width: '100%',
  height: '40px',
  border: 'none',
  font: '-webkit-small-control',
  textAlign: 'center',
  fontWeight: 'bold'
}

const Authorization = localStorage.getItem("token");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + Authorization,
};
let inqueryLocal = [];

function ItemInquery(props) {
  const [fabricColors, setFabricColors] = React.useState([]);
  const [comSize, setComSize] = React.useState([]);
  const [totalsReqSize, setTotalsReqSize] = React.useState([]);
  const [fabricAverage, setFabricAverage] = React.useState(0.0);
  const [isDataStockLoaded, setIsDataStockLoaded] = React.useState(false);

  const handleChangeValue = React.useCallback((id, size, qte) => {
    const comQte = {
      productId: parseInt(props.data.id),
      colorId: id,
      size,
      quantity: qte
    }
    const selectTotalColor = totalsReqSize.find(trs => trs.colorId === id);
  
    if (comSize.length === 0) {
      setComSize([...comSize, ...[comQte]]);
    }
  
    if (comSize.length !== 0) {
      const findCom = comSize.find(cs => cs.colorId === id && cs.size === size);
      if (findCom) {
        const updateFindCom = comSize.map(cs => {
          if (cs.colorId === id && cs.size === size) {
            cs.quantity = qte
          }
          return cs;
        });
        setComSize(updateFindCom);
      }
      if (!findCom) {
        setComSize([...comSize, ...[comQte]]);
      }
      selectTotalColor.totalRequest = comSize.filter(cs => cs.colorId === id).reduce((a, b) => a + b.quantity, 0);
      setTotalsReqSize(totalsReqSize.map(trs => {
        if (trs.colorId === id) {
          trs.totalRequest = selectTotalColor.totalRequest;
          trs.totalLeft = parseInt(parseFloat(trs.stock_color_tissus) / (fabricAverage * selectTotalColor.totalRequest));
        }
        return trs;
      }));
    }
  }, [props.data.id, comSize, totalsReqSize, fabricAverage, setComSize, setTotalsReqSize]);

  React.useEffect(() => {
    setFabricColors(props.data.colors);

    if(totalsReqSize.length === 0) {
      const tabC = []
      props.data.colors.map(color => {
        const colorReq = {
          colorId: color.id,
          totalRequest: 0,
          totalLeft: parseInt(parseFloat(color.stock_color_tissus) / parseFloat(props.stock?.catalogs[0]?.consomation_moyenne)),
          stock_color_tissus: color.stock_color_tissus,
          stock_total_tissus: color.stock_total_tissus,
          productId: props.data.id 
        };
        tabC.push(colorReq);
        return false;
      });
      setTotalsReqSize(tabC);

      if(window.localStorage.getItem('inquery')) {
        inqueryLocal = JSON.parse(window.localStorage.getItem('inquery'));
        const filterLocal = inqueryLocal.find(il => il.request[0].productId === parseInt(props.data.id));

        if(filterLocal) {
          filterLocal.request.map(flr => {
            handleChangeValue(flr.colorId, flr.size, flr.quantity);
            return true;
          })
        }
      }
    }

    if(!isDataStockLoaded) {
      Axios.get(`${BASE_URL}/api/v1/stock/list`, { headers })
      .then(response => {
        const stockList = response.data.data.find(d => d.catalogs.length !== 0 && d.catalogs.find(p => p.reference === props.data.reference && p.name === props.data.name));
        const qteMoyenne = parseFloat(stockList.catalogs[0].consomation_moyenne);
        setIsDataStockLoaded(true);
        setFabricAverage(qteMoyenne);
      })
    }


  }, [props, comSize, totalsReqSize, handleChangeValue, isDataStockLoaded]);

  const addItem = () => {
    const inquery = window.localStorage.getItem('inquery');
    let iq = [];
    if(inquery) {
      if(JSON.parse(inquery).find(o => o.request[0].productId === comSize[0].productId)) {
        iq = JSON.parse(inquery).map(o => {
          if(o.request[0].productId === comSize[0].productId) {
            return {
              reqTotal: totalsReqSize,
              request: comSize
            }
          }
          return o
        })
      }
      if(!JSON.parse(inquery).find(o => o.request[0].productId === comSize[0].productId)) {
        iq = JSON.parse(inquery);
        iq.push({
          reqTotal: totalsReqSize,
          request: comSize
        })
      }
    }

    if(!inquery) {
      iq.push({
        reqTotal: totalsReqSize,
        request: comSize
      })
    }
    if(iq.length !== 0) {
      window.localStorage.setItem('inquery', JSON.stringify(iq));
      message.success('Item added with sucess');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  const findLastQte = (item, vari) => {
    const filterLocal = inqueryLocal.find(il => il.request[0].productId === parseInt(props.data.id));
    const flq = filterLocal ? filterLocal.request.find(ilq => ilq.colorId === item.id && ilq.size === vari.value) : null;

    return flq ? flq.quantity : 0;
  }
  
  return (
    <Row className='detail-modal mb-5'>
      <Col flex="1 1 150px">
        <h2 className='detail-items__name'>
          <Element className="element" name={props.data.name}>
            {props.data.name}
          </Element>
        </h2>
        <h3 className='detail-items__title'>{props.data?.category?.label} • {props.data.range} • {props.data.reference}</h3>
        <div className='detail-items__swatch'>
            <div className='item-image__couleur'>
                {
                    props.data.colors.map((item)=>{
                        var classe = 'item-image__swatch';
                        if(item.image){
                            return (
                              <Link key={`link-${item.id}`} activeClass="active" className={`#${props.data.name}-${props.data.reference}-${item.label}`} to={`#${props.data.name}-${props.data.reference}-${item.label}`} spy={true} smooth={true} duration={500} >
                                <img className={classe} title={item.label} alt="images" src={BASE_URL+item?.image?.path} width={50} height={81.95} />
                              </Link>
                            );
                        }else{
                            return (
                              <Link key={`link-${item.id}`} activeClass="active" className={`#${props.data.name}-${props.data.reference}-${item.label}`} to={`#${props.data.name}-${props.data.reference}-${item.label}`} spy={true} smooth={true} duration={500} >
                                <img className={classe} title={item.label} alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" width={50} height={81.95} />
                              </Link>
                            );
                        }
                    })
                }
            </div>
        </div>    
        <div
          style={{
            backgroundColor: "#f1f1f1",
          }}
        >
          {
             fabricColors.map((item)=>(
              <Element key={`element-${item.id}`} style={{padding: '2px'}} className="element" name={`${props.data.name}-${props.data.reference}-${item.label}`}>
                <Row gutter={0.5} wrap={false}>
                  <Col span={2}>
                    <Space direction="vertical" size={'small'} align="center" className="pt-2 px-3 b-white" style={{width: '100%', height: 92, backgroundColor: 'white', marginTop: 2}}>
                      <Space.Compact direction="vertical" align='center'>
                        <div
                          className="pt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          {
                            item.image ? (
                              <>
                                <img
                                  style={{
                                    width: "27px",
                                  }}
                                  alt="images" src={BASE_URL+item?.image?.path} 
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  style={{
                                    width: "27px",
                                  }}
                                  alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                                />
                              </>
                            )
                          }
                        </div>
                        <div
                          className="pt-1"
                          style={{
                            fontWeight: "bold",
                            fontSize: "10px",
                            textAlign: 'center',
                            letterSpacing: 0,

                            width: "110%",
                          }}
                        >
                          {props.data.reference}
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "9px",
                            textAlign: 'center',
                            letterSpacing: 0,

                            width: "110%",
                          }}
                        >
                          {item.label}
                        </div>
                      </Space.Compact>
                    </Space>
                  </Col>
                  <Col flex={1}>
                    {" "}
                    <div style={styleCol1}>
                      <Row gutter={3}>
                        <Col flex={1}>
                          <div
                            className="pt-1"
                            style={{
                              fontWeight: "bold",

                              fontSize: 10,
                            }}
                          >
                            SIZE
                          </div>
                        </Col>
                        {
                          item.variantes &&  item.variantes.map(vari => (
                            <Col key={`size-${vari.id}`} span={3}>
                              <div
                                className="pt-1"
                                style={{
                                  padding: 1,
                                  display: "grid", // Utilisation de CSS Grid
                                  gridTemplateRows: "1fr 1fr", // Deux lignes de hauteur égale
                                  justifyContent: "center", // Centrer horizontalement
                                  alignItems: "center", // Centrer verticalement
                                }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: "bold",
                                    lineHeight: 2,
                                    fontSize: 10,
                                  }}
                                >
                                  {vari.value}
                                </p>
                                <p style={{ margin: 0, lineHeight: 1, fontSize: 10, color: '#696868' }}>
                                  {vari.reference_ys}
                                </p>
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                    </div>
                    <div style={styleCol2}>
                      <Row gutter={3}>
                        <Col flex={1}>
                          <div
                            style={{
                              backgroundColor: "white",

                              fontSize: 10,
                            }}
                          >
                            Quantity
                          </div>
                        </Col>
                        {
                          item.variantes &&  item.variantes.map(vari => (
                            <Col key={`qte-${vari.id}`} span={3}>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: 'auto  !important'
                                }}
                              >
                                <input 
                                  type="number"
                                  defaultValue={
                                    findLastQte(item, vari)
                                  }
                                  min={0}
                                  style={styleInput}
                                  onChange={(e) => handleChangeValue(item.id, vari.value, parseInt(e.target.value))}
                                />
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div style={{...block3, height: '46.5px'}}>
                      <div style={carres} className="py-1">
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              lineHeight: 2,
                              fontSize: 10,
                            }}
                          >
                            TOTAL
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              lineHeight: 1,
                              fontSize: 10,
                            }}
                          >
                            REQUEST
                          </p>
                        </div>
                      </div>
                      <div style={carres} className="py-1">
                        <div>
                          <p
                            style={{
                              color: "#68DC74",
                              margin: 0,
                              fontWeight: "bold",
                              lineHeight: 2,
                              fontSize: 10,
                            }}
                          >
                            TOTAL
                          </p>
                          <p
                            style={{
                              color: "#68DC74",
                              margin: 0,
                              fontWeight: "bold",
                              lineHeight: 1,
                              fontSize: 10,
                            }}
                          >
                            LEFT
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={block3}>
                      <div style={carres}>
                        {
                          totalsReqSize.find(trs => trs.colorId === item.id)?.totalRequest === 0 ? (
                            inqueryLocal.find(il => il.request[0].productId === parseInt(props.data.id))?.reqTotal?.find(trs => trs.colorId === item.id)?.totalRequest || totalsReqSize.find(trs => trs.colorId === item.id)?.totalRequest
                          ) : (
                            totalsReqSize.find(trs => trs.colorId === item.id)?.totalRequest
                          )
                        }
                      </div>
                      <div style={carres}>
                        {
                          totalsReqSize.find(trs => trs.colorId === item.id)?.totalRequest === 0 ? (
                            inqueryLocal.find(il => il.request[0].productId === parseInt(props.data.id))?.reqTotal?.find(trs => trs.colorId === item.id)?.totalLeft || totalsReqSize.find(trs => trs.colorId === item.id)?.totalLeft
                          ) : (
                            totalsReqSize.find(trs => trs.colorId === item.id)?.totalLeft
                          )
                        }
                      </div>
                    </div>
                  </Col>
                </Row>

                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "2px",
                  }}
                >
                  <Space align="center">
                    {
                      typeof(totalsReqSize.find(trs => trs.colorId === item.id)?.totalLeft) === 'number' &&
                      totalsReqSize.find(trs => trs.colorId === item.id)?.totalLeft !== 0 ? (
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#1890FF",

                            width: 150,
                            fontSize: "70%",
                          }}
                          onClick={() => addItem()}
                        >
                          ADD ITEM
                        </Button>
                      ) : (<></>)
                    }
                    {
                      inqueryLocal.find(il => il.request[0].productId === parseInt(props.data.id))?.request?.find(trs => trs.colorId === item.id) ? (
                        <CheckCircleOutlined style={{ color: 'rgb(123 233 134)', fontSize: 32 }} />
                      ) : (<></>)
                    }
                  </Space>
                </div>
              </Element>
             ))
          }
        </div>
      </Col>
  </Row>
  );
}

export default ItemInquery;