import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, Input, List, Form, Modal, Typography, Select, Checkbox , Switch , Collapse } from "antd";
import Meta from "antd/lib/card/Meta";
import '../../../assets/css/items.css';
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import DetailProduct from "../../layouts/detail_product";
import BASE_URL from "../../../config";
import Axios from "axios";
import { Utils } from "../../../services/Utils";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addPanier } from "../../../my_redux/actions/orders";
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const colors = [
    { label: 'NAVY', value: 'NAVY' },
    { label: 'BLACK', value: 'BLACK' },
    { label: 'BEIGE', value: 'BEIGE' },
    { label: 'GREY', value: 'GREY' },
    { label: 'PLATINUM', value: 'PLATINUM' },
    { label: 'RED', value: 'RED' },
    { label: 'BLUE', value: 'BLUE' },
];
const styles = [
    { label: 'MAN', value: 'MAN' },
    { label: 'WOMAN', value: 'WOMAN' }
];


const Items = (props) => {
    const [products, setProducts] = useState([]);
    const [idClicked, setIdClicked] = useState(null);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [role, setRole] = useState(localStorage.getItem("label") || null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [wordSearch, setWordSearch] = useState("");
    const [category, setCategory] = useState([]);
    const [formFilter] = Form.useForm();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsColors, setSelectedOptionsColors] = useState([]);
    const [selectedOptionsStyles, setSelectedOptionsStyles] = useState([]);
    const [filtersProducts, setFiltersProducts] = useState(null);
    const [filtersInStock, setFiltersInStock] = useState(false);


    
    const dispatch = useDispatch();

    const addPanierS = (product) =>{
        dispatch(addPanier(product))
        // console.log("===========================>",orders);
    }

    const detailProduct = (id) =>{
        setIdClicked(id);
    }

    useEffect(() => {
        
        getProducts();
        getCategory();
        
        // formFilter.setFieldsValue({
        //     category: 'All'
        // });
    }, []);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setFile(null);
        setFileName(null);
    };

    const submitImport = () =>{
        setIsLoading(true);
        const Authorization = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
        };
        const data = new FormData();
        data.append("catalog_file", file);
        Axios.post(`${BASE_URL}/api/v1/catalog/import`, data, { headers })
        .then(response => {
            setIsLoading(false);
            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message
                Utils.notif(type, mess);
            }
            setOpen(false);
            getProducts();
        })
        .catch(error => {
            setIsLoading(false);
            console.log(error.response.data.errors.email[0]);
            if(error.response.data.errors.email != null){
                let type = 'error'
                let mess = error.response.data.errors.email[0]
                Utils.notif(type, mess);
            }
            // setOpen(false);
        });
    }

    const onImportExcel = event => {
        const { files } = event.target;
        if (files.length >= 1) {
            setFile(files[0]);
            setFileName(files[0].name);
        }
    };

    const downloadTemplate = () => {      
        // replace the file path and name with your own
        const filePath = '/files/Template-import-catalogues.xlsx';
        const fileName = 'Template-import-catalogues.xlsx';
        const fileUrl = `${process.env.PUBLIC_URL}${filePath}`;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const addButton = (
        <>
            <Link
                to={{
                    pathname: `/dashboard/items/new`,
                    state: { modal: true },
                }}
                >  
            <Button type="primary" onClick={() => null} className="btn-shadow">
            + {props.t('add_items')}
            </Button>
            </Link>
            <Button style={{marginLeft:'10px'}} type="primary" onClick={showModal} className="btn-shadow">
            + {props.t('import_items')}
            </Button>
            <Modal
                open={open}
                title={props.t('import_items')}
                onCancel={handleCancel}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    {props.t('cancel_modal')}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={submitImport}>
                    {props.t('submit_import')}
                </Button>,
                ]}
            >
                <Button type="link" onClick={downloadTemplate}>
                    <span>{props.t('download_excel_template')}</span>
                </Button>
                <label htmlFor="files" className="drop-container mt-3">
                    { fileName==null &&
                        <span className="drop-title">{props.t('select_file')}</span>
                    }
                    { fileName!=null &&
                        <span className="drop-title">{fileName}</span>
                    }
                    <input style={{display:'none'}} id="files" className="file-uploader" type="file" accept=".xlsx, .xls, .csv" onChange={onImportExcel} />
                </label>
            </Modal>
        </>
    );

    const getProducts = (filterData = {}) => {
        setIsLoadingData(true);
        const Authorization = localStorage.getItem("token");
        const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
        };

        axios
        .post(`${BASE_URL}/api/v1/catalog/list`, {filterData}, { headers })
        .then((res) => {
            const productData = res.data?.data;
            setProducts(productData);
            setIsLoadingData(false);
        })
        .catch((error) => {
            setIsLoadingData(false);
            console.log(error);
        });
    };
    const onSearch = (value) => {
        const text = value.toLowerCase();
        setWordSearch(text);
    }

    const getCategory = () => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
          .then((res) => {
            const categoryData = res.data?.data;
            let tab = [];
            categoryData.forEach(obj => {
                const newObj = { ...obj, value: obj.id };
                tab.push(newObj);
            });
            setCategory(tab);
            console.log(tab);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const changeInstock = (e) => {
        console.log(e);
    }

    const handleFilter = (changedValue, allValues) => {
        
        if(allValues.category_id){
            allValues.category_id = allValues.category_id.toString();
        }
        if(allValues.range){
            allValues.range = allValues.range.toString();
        }
        if(allValues.colors){
            allValues.colors = allValues.colors.toString();
        }

        if(typeof allValues.quantity === "number"){
            allValues.quantity = allValues.quantity;
        }

        getProducts(allValues);
        console.log(allValues);

    };

    const totalStock = (item) => {
        const total = parseInt(item?.stocks?.in_stock[0]?.quantity?item?.stocks?.in_stock[0]?.quantity:0);

        return total;

    }

    return (
        <>
        
        <Card
        className="card-items"
        title={<Title level={3}>{props.t("list_items")}</Title>}
        extra={(role === "1" || role === "2") && addButton}
        >
             <Card style={{top:'46px',position:'sticky',zIndex:'99',marginBottom:'1px'}}>
                <div  className="row">
                    <div className="col-md-9">
                        <Form form={formFilter} onValuesChange={handleFilter} layout="inline"  style={{display:'inline-flex',justifyContent:'center'}}>
                            <Form.Item name="category_id">
                                <Select
                                    mode="multiple"
                                    style={{ minWidth: '100px',marginBottom:'10px' }}
                                    showArrow
                                    placeholder="Category"
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {category &&
                                    category.map((item) => (
                                        <Select.Option key={`key-${item.id}`} value={item.id}>
                                        {item.label}
                                        </Select.Option>
                                    ))}
                                </Select> 
                            </Form.Item>
                            <Form.Item name="range">
                                <Select
                                    mode="multiple"
                                    style={{ minWidth: '100px',marginBottom:'10px' }}
                                    showArrow
                                    placeholder="Styles"
                                    showSearch="false"
                                    optionFilterProp="children"
                                    onChange={(values) => setSelectedOptionsStyles(values)}
                                >
                                    {styles &&
                                    styles.map((item) => (
                                        <Select.Option key={`key-${item.value}`} value={item.value}>
                                        {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="colors">
                                <Select
                                    mode="multiple"
                                    showArrow
                                    style={{ minWidth: '100px',marginBottom:'10px' }}
                                    placeholder="Colors"
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {colors &&
                                    colors.map((item) => (
                                        <Select.Option key={`key-${item.id}`} value={item.label}>
                                        {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="quantity">
                                <Input style={{marginBottom:'10px'}} placeholder="Stock higher than" type="number"/>
                            </Form.Item>
                            <div style={{display:'flex',alignItems:'center'}}>
                            <span style={{marginRight:'10px'}}>All items</span>
                            <Form.Item name="in_stock" >
                                
                                <Switch defaultChecked={false} onChange={(value) => {
                                        setFiltersInStock(value) 
                                    }
                                } className="rounded" />
                            </Form.Item>
                            <span>In stock</span>
                            </div>
                        </Form>
                    </div>
                    <div className="col-md-3">
                        <Search
                            placeholder={props.t("search_for_catalogue")}
                            // allowClear
                            enterButton
                            onKeyUp={(event) => onSearch(event.target.value)}
                        />
                    </div>
                </div>
                
                
                
            </Card>
           
            <div className="row mt-2">
                    {/* <div className="col-md-3 col-12">
                        <Form form={formFilter} onValuesChange={handleFilter} layout="inline">
                            <Collapse defaultActiveKey={['5']} className="w-100 bg-white">
                                <Panel header="Category" key="1">
                                    <Form.Item name="category_id" valuePropName="checked">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={category}
                                            value={selectedOptions}
                                            onChange={(values) => setSelectedOptions(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Style" key="3">
                                    <Form.Item name="range">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={styles}
                                            value={selectedOptionsStyles}
                                            onChange={(values) => setSelectedOptionsStyles(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Colors" key="4">
                                    <Form.Item name="colors">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={colors}
                                            value={selectedOptionsColors}
                                            onChange={(values) => setSelectedOptionsColors(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Stock" key="5">
                                    <Form.Item name="in_stock" label="In Stock">
                                        <Switch defaultChecked onChange={(value) => setFiltersInStock(value)} className="rounded" />
                                    </Form.Item>
                                    <Form.Item name="quantity" label="Stock higher than">
                                        <Input type="number"/>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                        </Form>
                    </div> */}
                    <div className="col-md-12 col-12 items-order">
                        <List
                            grid={{ gutter: 16, 
                                xs: 2,
                                sm: 3,
                                md: 5,
                                lg: 6,
                                xl: 6,
                                xxl: 6, 
                            }}
                            dataSource={products.filter((item) => {
                                if (
                                item.reference.toLowerCase().includes(wordSearch) ||
                                item.name.toLowerCase().includes(wordSearch)
                                ) {
                                return true;
                                }
                                return false;
                            })}
                            pagination={{ hideOnSinglePage: true, pageSize: 24 }}
                            renderItem={(item) => (
                            <List.Item>
                                    <Card
                                    className="card-item"
                                    extra={(
                                        (role === "1" || role === "2" || role === "4") &&
                                        <Link to={{pathname: `/dashboard/items/${item.id}`}} >
                                            <EditOutlined twoToneColor="blue" style={{fontSize:'20px',color:'black',backgroundColor:'green'}} />
                                        </Link>
                                        
                                    )}
                                    hoverable={true}
                                    onClick={() => detailProduct(item.id)}
                                    style={{ textAlign: 'center',padding:'1px'}}
                                    cover={
                                        
                                        (
                                            item.images[0] ?
                                                <img alt="images" src={BASE_URL+item?.images[0].path} />
                                            :
                                                <img alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" />
                                        )     
                                    }
                                    >
                                        <Meta 
                                            title={(
                                                <>
                                                    <b>{item.name}</b>
                                                    <br />
                                                    <small>{item.reference}</small>
                                                </>
                                            )} 
                                            description={(
                                                <>
                                                    <small>In Stock : <b>{item.quantity}</b></small>
                                                </>
                                            )}
                                        />
                                    </Card>
                                    {
                                    item.id === idClicked &&
                                        (
                                            <DetailProduct id={item.reference} addPanier={addPanierS} setCLick={setIdClicked} props={props} data={item} />
                                        ) 
                                    }
                            </List.Item>
                            )}
                            loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
                        />  
                    </div>
            </div>
        </Card>
        </>
    );
};

export default withTranslation()(Items);
