import React, { useEffect, useState } from 'react';
import { withTranslation  } from 'react-i18next';
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Button, Select, Form, Input, Card, Typography, Checkbox } from "antd";
import BASE_URL from "../../../config.js";
import SweetAlert from 'sweetalert2-react';
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const EditUsers = (props) => {
    const params = useParams();
    const [formUser] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState(JSON.parse(localStorage.getItem('LIST_ROLES')));
    const [show, setShow] = useState(false);
    const [notif, setNotif] = useState("");
    const [isDifferent, setIsDifferent] = useState(false);
    
    useEffect(() => {
        getUser(params.id);
    }, []);

    const getUser = (id) => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/user/show/${id}`, { headers })
          .then((res) => {
            const user = res.data?.data;
            formUser.setFieldsValue({
                name: user.name,
                firstname: user.firstname,
                email: user.email,
                role_id: parseInt(user.role_id),
                address: user.delivery_address,
                company:user.company_name,
                tva:user.tva_number,
                acompte:user.acompte,
                remise:user.remise,
                address_livraison:user.billing_address
            });
            if(user.is_same_adress=="0"){
                setIsDifferent(true);
            }else{
                setIsDifferent(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const saveUser = async (value) => {
        setIsLoading(true);
        const { name, firstname, role_id, email, address,tva,company,address_livraison,acompte,remise } = value;
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/user/edit/${params.id}`, { is_same_adress: !isDifferent,name, firstname, role_id, email, delivery_address:address,tva_number:tva,company_name:company,billing_address:address_livraison,acompte:acompte,remise:remise }, { headers });

            setIsLoading(false);

            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message;

                Utils.notif(type, mess);
                // setShow(true);
                // setNotif(mess);

                // props.history.push("/dashboard/users"); 
            } 

        } catch (error) {
            setIsLoading(false);
            console.log(error);    
        } 
    };
    return (
        <>
            <Card title={<Title level={3}>{props.t('edit_user')}</Title>}>
            <div className="row">
            <div className="col-md-12">
            <Form
                form={formUser}
                name="edit-user"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                onFinish={saveUser}
                onFinishFailed={null}
                autoComplete="off"
            >
                <Form.Item
                label={props.t("name")}
                name="name"
                rules={[
                    {
                    required: true,
                    message: "Please input name!",
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label={props.t("firstname")}
                name="firstname"
                rules={[
                    {
                    required: true,
                    message: "Please input firstname!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label={props.t("company_name")}
                name="company"
                rules={[
                    {
                    required: true,
                    message: "Please input company name!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label={props.t("role")}
                name="role_id"
                rules={[
                    {
                    required: true,
                    message: "Please input role!",
                    },
                ]}
                >
                <Select
                    allowClear
                    placeholder={props.t('choose_role')}
                    showSearch
                    optionFilterProp="children"
                >
                    {
                        roles && roles.map((item) => (
                            <Select.Option
                                key={`key-${item.id}`}
                                value={item.id}
                            >
                                {item.label}
                            </Select.Option>
                        ))
                    }
                </Select>
                </Form.Item>
                <Form.Item
                label={props.t("tva_number")}
                name="tva"
                rules={[
                    {
                    required: true,
                    message: "Please input TVA Number!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item name="address_livraison" label={props.t("delivery_address")}
                rules={[
                    {
                    required: true,
                    message: "Please input Billing Adress!",
                    },
                ]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                label="If it is different delivery address"
                name="different"
                style={{marginTop:'-25px'}}
                >
                <Checkbox checked={isDifferent} onChange={()=>setIsDifferent(!isDifferent)} />
                </Form.Item>
                {
                    isDifferent &&(
                        <Form.Item name="address" label={props.t("address")}>
                            <Input.TextArea />
                        </Form.Item>
                    )
                }
                <Form.Item
                label={props.t("email")}
                name="email"
                rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid Email!',
                    },
                    {
                      required: true,
                      message: 'Please input Email!',
                    },
                ]}
                >
                <Input autoComplete="off" />
                </Form.Item>

                <Form.Item
                label={props.t('pc_remise')}
                name="remise"
                rules={[
                    {
                    required: true,
                    message: "Please input remise!",
                    },
                ]}
                >
                <Input type='number' />
                </Form.Item>
                
                <Form.Item
                label={props.t('pc_acompte')}
                name="acompte"
                rules={[
                    {
                    required: true,
                    message: "Please input acompte!",
                    },
                ]}
                >
                <Input type='number' />
                </Form.Item>
                
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="mr-3"
                >
                    {props.t("btn_save")}
                </Button>
                <Link
                    to='/dashboard/users'
                >
                    {props.t('retour_liste')}
                </Link>
                </Form.Item>
            </Form>
            </div>
        </div>
        </Card>
        <SweetAlert
            show={show}
            type="success"
            text={notif}
            onConfirm={() => setShow(false)}
        />
        </>
    );
}

export default withTranslation()(EditUsers)