import { Button, Carousel, Col, Image, Input, Modal, Row, Space, Tabs, Typography } from 'antd';
import React,{ Component } from 'react';
import '../../assets/css/detailProduct.scss';
import BASE_URL from '../../config';
import axios from "axios";
import confirm from 'antd/lib/modal/confirm';
import { Utils } from '../../services/Utils';
import { Redirect, useHistory } from 'react-router-dom';
const { Text } = Typography;

class DetailProductOLD extends Component {
    state = {
        modalOpen:true,
        disponible:"",
        primaryImage:this.props.data?.images[0]?.path?BASE_URL+this.props.data.images[0].path:"https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg",
        secondaryImage:this.props.data.colors[0].image?BASE_URL+this.props.data.colors[0].image.path:"https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg",
        activeColor: this.props.data.colors[0],
        media: null,
        nav: null,
        stocks:null,
        stockMax:0,
        insuff:false,
        commande:[],
        redirect: false
    }

    changeIn = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            if(variant.stocks.in_stock.length==0){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK`);
                return;
            }
            // if(parseInt(event.target.value)>variant.stocks.in_stock[0].quantity){
            //     let type = "error";
            //     Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.in_stock[0].quantity} quantities`);
            //     // this.setState({insuff:true,stockMax:variant.stocks.in_stock[0].quantity});
            //     const el = document.getElementById(color.id+"-"+variant.id+'-in');
            //     el.value = variant.stocks.in_stock[0].quantity;
            //     const temp = [...this.state.commande];
            //     var test = true;
            //     temp.map((item,i)=>{
            //         if(item.color.id==color.id && item.variant.id==variant.id){
            //             temp[i].in = variant.stocks.in_stock[0].quantity;
            //             test = false;
            //         }
            //     })
            //     if(test){
            //         temp.push({
            //             color:color,
            //             variant:variant,
            //             in:variant.stocks.in_stock[0].quantity,
            //             max:variant.stocks.in_stock[0].quantity
            //         })
            //     }
            //     this.state.commande = temp;
            // }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].in = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        in:event.target.value,
                        max:variant.stocks.in_stock[0].quantity
                    })
                }
                this.state.commande = temp;
            // }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].in = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    in:undefined,
                    max:variant.stocks.in_stock[0].quantity
                })
            }
            this.state.commande = temp;
        }   
    }


    changeOne = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            if(variant.stocks.future_one.length==0){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK`);
                return;
            }
            if(parseInt(event.target.value)>variant.stocks.future_one[0].quantity){
                // this.setState({insuff:true,stockMax:variant.stocks.future_one[0].quantity});
                let type = "error";
                Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.future_one[0].quantity} quantities`);
                // this.setState({modalOpen:false})
                const el = document.getElementById(color.id+"-"+variant.id+'-one');
                el.value = variant.stocks.future_one[0].quantity;
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].one = variant.stocks.future_one[0].quantity;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        one:variant.stocks.future_one[0].quantity,
                        max:variant.stocks.future_one[0].quantity
                    })
                }
                this.state.commande = temp;
            }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].one = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        one:event.target.value,
                        max:variant.stocks.future_one[0].quantity
                    })
                }
                this.state.commande = temp;
            }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].one = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    one:undefined,
                    max:variant.stocks.future_one[0].quantity
                })
            }
            this.state.commande = temp;
        }
    }

    changeTwo = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            if(variant.stocks.future_two.length==0){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK`);
                return;
            }
            if(parseInt(event.target.value)>variant.stocks.future_two[0].quantity){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.future_one[0].quantity} quantities`);
                // this.setState({insuff:true,stockMax:variant.stocks.future_two[0].quantity});
                const el = document.getElementById(color.id+"-"+variant.id+'-two');
                el.value = variant.stocks.future_two[0].quantity;
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].two = variant.stocks.future_two[0].quantity;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        two:variant.stocks.future_two[0].quantity,
                        max:variant.stocks.future_two[0].quantity
                    })
                }
                this.state.commande = temp;
            }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].two = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        two:event.target.value,
                        max:variant.stocks.future_two[0].quantity
                    })
                }
                this.state.commande = temp;
            }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].two = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    two:undefined,
                    max:variant.stocks.future_two[0].quantity
                })
            }
            this.state.commande = temp;
        }
    }
    
    init = (color) =>{
        color.variantes.map((item)=>{
            const el = document.getElementById(color.id+"-"+item.id+'-in');
        
            el.addEventListener('input', (e) => this.changeIn(e,color,item));

            const elOne = document.getElementById(color.id+"-"+item.id+'-one');
        
            elOne.addEventListener('input', (e) => this.changeOne(e,color,item));

            const elTwo = document.getElementById(color.id+"-"+item.id+'-two');
        
            elTwo.addEventListener('input', (e) => this.changeTwo(e,color,item));
        })
    }
    
    componentDidUpdate = () =>{
        this.init(this.state.activeColor);
    }

    componentDidMount = () => {
        this.setState({
            media: this.media,
            nav: this.nav
        });
        // this.init(this.state.activeColor);
    };

    codeColor = (quantity) =>{
        if(quantity<=0){
            return `<td style="vertical-align: middle!important;"><span class="bg-danger" style="font-weight: bold;display: inline-block;color:white;width: 44px;">${quantity}</span></td>`;
        }else if(quantity>0 && quantity<=12){
            return `<td style="vertical-align: middle!important;"><span class="bg-warning" style="font-weight: bold;display: inline-block;width: 44px;">${quantity}</span></td>`;
        }else if(quantity>12){
            return `<td style="vertical-align: middle!important;"><span class="bg-success" style="font-weight: bold;display: inline-block;color:white;width: 44px;">${quantity}</span></td>`;
        }
    }

    monComposant = (color) => {
        console.log("===================>",color)
        this.state.disponible = 
        `<div style="overflow-x: auto;">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col" class="title" style="font-weight: bold;" >Disponible le</th>
                    <th scope="col" style="font-weight: bold;">Total</th>`;
        var size = "";      
        color.variantes.map((item)=>{
            size += `<th scope="col"><p style="margin:0;font-weight: bold;">${item.value}</p> <p style="margin:0;">${item.reference_ys}</p></th>`;
        })
        this.state.disponible +=size+`
                </tr>
                </thead>
                <tbody>`;
        var ligne = "";
        

        var total = {
            in:0,
            one:0,
            two:0
        };

        color.variantes.map((item)=>{
            total.in = parseInt(total.in) + parseInt(item?.stocks?.in_stock[0]?.quantity?item?.stocks?.in_stock[0]?.quantity:0);
            total.one = parseInt(total.one)+ parseInt(item?.stocks?.future_one[0]?.quantity?item?.stocks?.future_one[0]?.quantity:0);
            total.two = parseInt(total.two)+ parseInt(item?.stocks?.future_two[0]?.quantity?item?.stocks?.future_two[0]?.quantity:0);
        })


        ligne += `<tr>`;
        color.variantes.map((item,i)=>{
            if(i==0){
                ligne += `<th class="title">Aujourd'hui</th>`;
                ligne += `<td style="vertical-align: middle!important;"><span style="font-weight: bold;display: inline-block;width: 44px;">${total.in}</span></td>`;
            }
            ligne += this.codeColor(item?.stocks?.in_stock[0]?.quantity?item?.stocks?.in_stock[0]?.quantity:0);            
        })
        ligne += `</tr>`;

        ligne += `<tr>`;
        
        color.variantes.map((item,i)=>{
            if(i==0){
                ligne += `<th class="commande-input"></th>`;
                ligne += `<td class="commande-input"></td>`;
            }
            let inT = true;
            this.state.commande.map((itemC)=>{
                if(itemC.color.id==color.id){
                    if(itemC.variant.id==item.id){
                        inT = false;
                        ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-in" class="input-commande" value="${itemC?.in?itemC?.in:null}" type="number"/></td>`;  
                    }
                }
            })
            if(inT){
                ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-in" class="input-commande" type="number"/></td>`;  
            }
        })
        ligne += `</tr>`;
        

        ligne += `<tr>`;
        color.variantes.map((item,i)=>{
            if(i==0){
                ligne += `<th class="title">${item?.stocks?.future_one[0]?.date_disponibility?'Next stock arrival <br>'+item?.stocks?.future_one[0]?.date_disponibility:'Next stock arrival'}</th>`;
                ligne += `<td style="vertical-align: middle!important;" ><span style="font-weight: bold;display: inline-block;width: 44px;">${total.one}</span></td>`;
            }
            ligne += this.codeColor(item?.stocks?.future_one[0]?.quantity?item?.stocks?.future_one[0]?.quantity:0);  
        })
        ligne += `</tr>`;


        ligne += `<tr>`;
        color.variantes.map((item,i)=>{
            if(i==0){
                ligne += `<th class="commande-input"></th>`;
                ligne += `<td class="commande-input"></td>`;
            }
            let twoT = true;
            this.state.commande.map((itemC)=>{
                if(itemC.color.id==color.id){
                    if(itemC.variant.id==item.id){
                        twoT = false;
                        ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-one" class="input-commande" value="${itemC?.one?itemC?.one:null}" type="number"/></td>`;  
                    }
                }
            })
            if(twoT){
                ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-one" class="input-commande" type="number"/></td>`;  
            }   
        })
        ligne += `</tr>`;

        ligne += `<tr>`;
        color.variantes.map((item,i)=>{
            console.log("item",item)
            if(i==0){
                ligne += `<th class="title">${item?.stocks?.future_two[0]?.date_disponibility?'Next stock arrival <br>'+item?.stocks?.future_two[0]?.date_disponibility:'Next stock arrival'}</th>`;
                ligne += `<td style="vertical-align: middle!important;"><span style="font-weight: bold;display: inline-block;width: 44px;">${total.two}</span></td>`;
            }
            ligne += this.codeColor(item?.stocks?.future_two[0]?.quantity?item?.stocks?.future_two[0]?.quantity:0);  
        })
        ligne += `</tr>`;


        ligne += `<tr>`;
        color.variantes.map((item,i)=>{
            console.log("item",item)
            if(i==0){
                ligne += `<th class="commande-input"></th>`;
                ligne += `<td class="commande-input"></td>`;
            }
            let twoT = true;
            this.state.commande.map((itemC)=>{
                if(itemC.color.id==color.id){
                    if(itemC.variant.id==item.id){
                        twoT = false;
                        ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-two" class="input-commande" value="${itemC?.two?itemC?.two:null}" type="number"/></td>`;  
                    }
                }
            })
            if(twoT){
                ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-two" class="input-commande" type="number"/></td>`;  
            }
        })
        ligne += `</tr>`;

        this.state.disponible +=ligne;
        return (
          <div dangerouslySetInnerHTML={{ __html: this.state.disponible }} />
        );
    }

    constructor(props) {
        super(props);
        this.state.stocks = 
            [
                   {
                    key: '3',
                    label: `Disponibilite`,
                   //  disabled:true,
                    children: this.monComposant(this.props.data?.colors[0]),
                  },
                   {
                    key: '1',
                    label: `Description`,
                    children: `${this.props.data?.description}`,
                  },
                  {
                    key: '2',
                    label: `Matiere`,
                    children: `${this.props.data?.fabric?.label}`,
                  }
            ];
    }


    
    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/dashboard/cart'/>;
        }
        const submitOrder = () =>{
            console.log(this.state.commande);
            console.log(this.props.data);
        }

        const changeColor = (item) =>{
            this.setState({activeColor:item});
            this.setState({
                stocks: 
                    [
                        {
                            key: '3',
                            label: `Disponibilite`,
                            children: this.monComposant(item),
                        },
                        {
                            key: '1',
                            label: `Description`,
                            children: `${this.props.data?.description}`,
                        },
                        {
                            key: '2',
                            label: `Matiere`,
                            children: `${this.props.data?.fabric?.label}`,
                        }
                    ],
            });
        }
        
        const jsonFormat = (inA,oneA,twoA) =>{
            const res = {cart:[],one:[],two:[]};
            inA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        in:itemC.in,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.cart.push(temp)
                }
            })
            oneA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        one:itemC.one,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.one.push(temp)
                }
            })
            twoA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        two:itemC.two,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.two.push(temp)
                }
            })
            return res;
        }
        const validerOrder = (product,productOne,productTwo) =>{
            if(product.length==0 && productOne.length==0 && productTwo.length==0){
                let type = "error";
                let mess = "Cart empty!";
                Utils.notif(type, mess);
                // setIsLoading(false);
                return;
            }
            console.log("product",product)
            console.log("productOne",productOne)
            console.log("productTwo",productTwo)
            console.log(jsonFormat(product,productOne,productTwo));
            const Authorization = localStorage.getItem("token");
            const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
            };
            axios.post(`${BASE_URL}/api/v1/command/add`, jsonFormat(product,productOne,productTwo), { headers })
            .then((response) => {
                // dispatch(deletePanier());
                const temp = [];
                // setProduct([...temp]);
                // setProductOne([...temp]);
                // setProductTwo([...temp]);
                if (response.data.success) {
                    let type = "success";
                    let mess = response.data?.message;
                    Utils.notif(type, mess);
                // clearForm();
                // history.push("/dashboard/items");
                }
            })
            .catch((error) => {
                if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
                }else{
                let type = "error";
                Utils.notif(type, "Error");
                }
            });
        }

        const ajoutPanier = () =>{
            console.log({product:this.props.data,commande:this.state.commande});
            const cart = [];
            const one = [];
            const two = [];

            const temp = {product:this.props.data,commande:[]};
            this.state.commande.map((itemC)=>{
                if(isNaN(itemC?.in)==false){
                    temp.commande.push({color:itemC.color,in:itemC.in,max:itemC?.max,variant:itemC.variant});
                }
            })
            if(temp.commande.length>0){
                cart.push(temp);
            }


            const temp1 = {product:this.props.data,commande:[]};
            this.state.commande.map((itemC)=>{
                if(isNaN(itemC?.one)==false){
                    temp1.commande.push({color:itemC.color,one:itemC.one,max:itemC?.max,variant:itemC.variant});
                }
            })
            if(temp1.commande.length>0){
                one.push(temp1);
            }
            
            const temp2 = {product:this.props.data,commande:[]};
            this.state.commande.map((itemC)=>{
                if(isNaN(itemC?.two)==false){
                    temp2.commande.push({color:itemC.color,two:itemC.two,max:itemC?.max,variant:itemC.variant});
                }
            })
            if(temp2.commande.length>0){
                two.push(temp2);
            }

            // this.props.addPanier({product:this.props.data,commande:this.state.commande});
            // let type = "success";
            // Utils.notif(type, "Add cart success");
            // this.setState({modalOpen:false})
            confirm({
                title:'',
                content: 
                <div style={{textAlign:'center'}}>
                    <img style={{width:"150px",height:"150px"}} src='https://img.freepik.com/free-vector/shopping-cart-realistic_1284-6011.jpg?w=2000'/>
                    <p><b>Are you sure to validate the order?</b></p>
                </div>,
                okText:'Confirm the order',
                cancelText:'Cancel',
                onOk:()=>{
                    validerOrder(cart,one,two);
                    this.setState({modalOpen:false});
                    this.props.setCLick(null);
                },
                onCancel: ()=>{
                    
                }

              });
        }

        return (
            <Modal
                className='detail-modal'
                // centered
                open={this.state.modalOpen}
                style={{ top: 10 }}
                onOk={() => {
                    this.setState({modalOpen:false})
                    this.props.setCLick(null);
                }}
                onCancel={() => {
                    this.setState({modalOpen:false});
                    this.props.setCLick(null);
                }}
                width={'1000'}
                footer={[]}
            >
                <Modal
                className='stock-insuffisant'
                // centered
                open={this.state.insuff}
                style={{ top: 10 }}
                onOk={() => {
                    this.setState({insuff:false})
                }}
                onCancel={() => {
                    this.setState({insuff:false});
                }}
                footer={[]}
                >
                    <h1 style={{fontSize:'15px',fontWeight:'bold'}}>OUT OF STOCK</h1> <span style={{color:'red'}}>You can order with up to {this.state.stockMax} quantities</span>
                </Modal>
             <Row>
                     <Col flex="1 1 100px" style={{marginRight:'10px',marginBottom:'10px'}}>
                        <Row className='detail-items__image'>
                            <Image className='item-image__primary' height={450} alt="images" src={this.state.primaryImage} />
                        </Row> 
                     </Col>
                     <Col flex="1 1 150px">
                        <h2 className='detail-items__name'>{this.props.data.name}</h2>
                        <h3 className='detail-items__title'>{this.props.data?.category?.label} • {this.props.data.range} • {this.props.data.reference}</h3>
                        <span className='detail-items__price'>
                            {
                                parseInt(this.props.data.price.remise)!=0 &&(
                                    <>
                                        <Text style={{fontStyle: 'italic'}}>Purchase price : {Utils.formatMoney(parseFloat(this.props.data.price.purchase_price))}</Text>
                                        <br></br>
                                    </>
                                )
                            }
                            
                            <Text style={{marginLeft:'2px'}}>Retail price : <span style={{marginLeft:'18px'}}>{Utils.formatMoney(parseFloat(this.props.data.price.purchase_price_remise))}</span></Text>
                            </span>
                            
                        <div className='detail-items__swatch'>
                            <div className='item-image__couleur'>
                                {
                                    this.props.data.colors.map((item)=>{
                                        var classe = 'item-image__swatch';
                                        if(this.state.activeColor.label==item.label){
                                            classe = 'item-image__swatch color-active';
                                        }
                                        if(item.image){
                                            return (
                                                <img className={classe} title={item.label} onClick={()=>{changeColor(item)}} alt="images" src={BASE_URL+item?.image?.path} width={50} />
                                                );
                                        }else{
                                            return (
                                                <img className={classe} title={item.label} onClick={()=>{changeColor(item)}} alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" width={50} />
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                     <Tabs
                         defaultActiveKey="3"
                         size='small'
                         style={{ marginBottom: 5 }}
                         items={this.state.stocks}
                     />
                    <div className='item-footer'>
                        <Button type='primary' onClick={() => {ajoutPanier()}}>
                        {this.props.props.t('add_cart')}
                        </Button>
                        {/* <Button onClick={() => {submitOrder()}} disabled className="btn-shadow">
                        {this.props.props.t('add_all_variants')}
                        </Button> */}
                    </div>
                         
                   </Col>
                 </Row>
                <div className='item-image__couleur' style={{marginTop:'20px'}}>
                    {
                        this.props.data.images.length>0 &&(
                            this.props.data.images.map((item)=>(
                                <img className='item-image__swatch' alt="images" src={BASE_URL+item.path} width={50} />
                            ))
                        )
                    }
                    {
                        this.props.data.images.length==0 &&(
                            (
                                <img className='item-image__swatch' alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" width={50} />
                            )
                        )
                    }
                </div>
            </Modal>
        );
    }
}

export default DetailProductOLD