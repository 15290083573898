import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, Input, List, Form, Modal, Typography, Select, Checkbox , Switch , Collapse } from "antd";
import Meta from "antd/lib/card/Meta";
import '../../../assets/css/items.css';
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import DetailProduct from "../../layouts/detail_product";
import BASE_URL from "../../../config";
import Axios from "axios";
import { Utils } from "../../../services/Utils";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addPanier } from "../../../my_redux/actions/orders";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const colors = [
    { label: 'NAVY', value: 'NAVY' },
    { label: 'BLACK', value: 'BLACK' },
    { label: 'BEIGE', value: 'BEIGE' },
    { label: 'GREY', value: 'GREY' },
    { label: 'PLATINUM', value: 'PLATINUM' },
    { label: 'RED', value: 'RED' },
    { label: 'BLUE', value: 'BLUE' },
];
const styles = [
    { label: 'MAN', value: 'MAN' },
    { label: 'WOMAN', value: 'WOMAN' }
];

const Stocks = (props) => {
    const [products, setProducts] = useState([]);
    const [idClicked, setIdClicked] = useState(null);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [role, setRole] = useState(localStorage.getItem("label") || null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [wordSearch, setWordSearch] = useState("");
    const [category, setCategory] = useState([]);
    const [formFilter] = Form.useForm();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsColors, setSelectedOptionsColors] = useState([]);
    const [selectedOptionsStyles, setSelectedOptionsStyles] = useState([]);
    const [filtersProducts, setFiltersProducts] = useState(null);
    const [filtersInStock, setFiltersInStock] = useState(false);


    
    const dispatch = useDispatch();
    const history = useHistory();
    const addPanierS = (product) =>{
        dispatch(addPanier(product))
        // console.log("===========================>",orders);
    }

    const detailProduct = (id) =>{
        // setIdClicked(id);
        history.push("/dashboard/stock/"+id);
    }

    useEffect(() => {
        
        getProducts();
        // getCategory();
        
        // formFilter.setFieldsValue({
        //     category: 'All'
        // });
    }, []);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setFile(null);
        setFileName(null);
    };

    const submitImport = () =>{
        setIsLoading(true);
        const Authorization = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
        };
        const data = new FormData();
        data.append("stock_file", file);
        Axios.post(`${BASE_URL}/api/v1/stock/import`, data, { headers })
        .then(response => {
            setIsLoading(false);
            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message
                Utils.notif(type, mess);
            }
            setOpen(false);
            getProducts();
        })
        .catch(error => {
            setIsLoading(false);
            console.log(error.response.data.errors.email[0]);
            if(error.response.data.errors.email != null){
                let type = 'error'
                let mess = error.response.data.errors.email[0]
                Utils.notif(type, mess);
            }
            // setOpen(false);
        });
    }

    const onImportExcel = event => {
        const { files } = event.target;
        if (files.length >= 1) {
            setFile(files[0]);
            setFileName(files[0].name);
        }
    };

    const downloadTemplate = () => {      
        // replace the file path and name with your own
        const filePath = '/files/Template-import-stock.xlsx';
        const fileName = 'Template-import-stock.xlsx';
        const fileUrl = `${process.env.PUBLIC_URL}${filePath}`;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const addButton = (
        <>
            <Link
                to={{
                    pathname: `/dashboard/stock/new`,
                    state: { modal: true },
                }}
                >  
            <Button type="primary" onClick={() => null} className="btn-shadow">
            + {props.t('add_stocks')}
            </Button>
            </Link>
            <Button style={{marginLeft:'10px'}} type="primary" onClick={showModal} className="btn-shadow">
            + {props.t('import_stocks')}
            </Button>
            <Modal
                open={open}
                title={props.t('import_stocks')}
                onCancel={handleCancel}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    {props.t('cancel_modal')}
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={submitImport}>
                    {props.t('submit_import')}
                </Button>,
                ]}
            >
                <Button type="link" onClick={downloadTemplate}>
                    <span>{props.t('download_excel_template')}</span>
                </Button>
                <label htmlFor="files" className="drop-container mt-3">
                    { fileName==null &&
                        <span className="drop-title">{props.t('select_file')}</span>
                    }
                    { fileName!=null &&
                        <span className="drop-title">{fileName}</span>
                    }
                    <input style={{display:'none'}} id="files" className="file-uploader" type="file" accept=".xlsx, .xls, .csv" onChange={onImportExcel} />
                </label>
            </Modal>
        </>
    );

    const getProducts = (filterData = {}) => {
        setIsLoadingData(true);
        const Authorization = localStorage.getItem("token");
        const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
        };

        axios
        .get(`${BASE_URL}/api/v1/stock/list`, { headers })
        .then((res) => {
            const productData = res.data?.data;
            setProducts(productData);
            setIsLoadingData(false);
        })
        .catch((error) => {
            setIsLoadingData(false);
            console.log(error);
        });
    };

    const getCategory = () => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
          .then((res) => {
            const categoryData = res.data?.data;
            let tab = [];
            categoryData.forEach(obj => {
                const newObj = { ...obj, value: obj.id };
                tab.push(newObj);
            });
            setCategory(tab);
            console.log(tab);
          })
          .catch((error) => {
            console.log(error);
          });
    };


    return (
        <>
        
        <Card
        className="card-items"
        title={<Title level={3}>{props.t("Stocks")}</Title>}
        extra={(role === "4") && addButton}
        >
            <div className="row mt-2">
                    {/* <div className="col-md-3 col-12">
                        <Form form={formFilter} onValuesChange={handleFilter} layout="inline">
                            <Collapse defaultActiveKey={['5']} className="w-100 bg-white">
                                <Panel header="Category" key="1">
                                    <Form.Item name="category_id" valuePropName="checked">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={category}
                                            value={selectedOptions}
                                            onChange={(values) => setSelectedOptions(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Style" key="3">
                                    <Form.Item name="range">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={styles}
                                            value={selectedOptionsStyles}
                                            onChange={(values) => setSelectedOptionsStyles(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Colors" key="4">
                                    <Form.Item name="colors">
                                        <Checkbox.Group
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            options={colors}
                                            value={selectedOptionsColors}
                                            onChange={(values) => setSelectedOptionsColors(values)}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Stock" key="5">
                                    <Form.Item name="in_stock" label="In Stock">
                                        <Switch defaultChecked onChange={(value) => setFiltersInStock(value)} className="rounded" />
                                    </Form.Item>
                                    <Form.Item name="quantity" label="Stock higher than">
                                        <Input type="number"/>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                        </Form>
                    </div> */}
                    <div className="col-md-12 col-12">
                        <List
                            grid={{ gutter: 16, 
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 4,
                                xl: 5,
                                xxl: 5, 
                            }}
                            dataSource={products}
                            pagination={{ hideOnSinglePage: true, pageSize: 24 }}
                            renderItem={(item) => (
                            <List.Item>
                                    <Card
                                    className="card-item py-5 px-2 border border-dark"
                                    // extra={(
                                    //     (role === "1" || role === "2") &&
                                    //     <Link to={{pathname: `/dashboard/items/${item.id}`}} >
                                    //         <EditOutlined twoToneColor="blue" style={{fontSize:'20px',color:'black',backgroundColor:'green'}} />
                                    //     </Link>
                                        
                                    // )}
                                    hoverable={true}
                                    onClick={() => detailProduct(item.id)}
                                    style={{ textAlign: 'center',padding:'1px'}}
                                    // cover={
                                    //     <img alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" />
                                    // }
                                    >
                                        <Meta 
                                            title={(
                                                <>
                                                    <b>{item.label}</b>
                                                    <br />
                                                </>
                                            )} 
                                            description={(
                                                <>
                                                    <small>Stock : <b>{item.quantity?item.quantity:0}</b></small>
                                                    <p style={{margin:'0',color:'#1890ff'}}><small>Arrival : <b>{item.quantity_arrival?item.quantity_arrival:0}</b></small></p>
                                                    <small>Stock : {item.last_date_check?item.last_date_check:new Date().getFullYear()+"-"+("0" + ((new Date().getMonth()+1))).slice(-2)+"-"+("0" + ((new Date().getDate()))).slice(-2)} </small>
                                                    <p style={{margin:'0',color:'#1890ff'}}><small>Arrival : {item.previsional_arrival_date?item.previsional_arrival_date:new Date().getFullYear()+"-"+("0" + ((new Date().getMonth()+1))).slice(-2)+"-"+("0" + ((new Date().getDate()))).slice(-2)}</small></p>
                                                </>
                                            )}
                                        />
                                    </Card>
                                    {
                                    item.id === idClicked &&
                                        (
                                            <DetailProduct id={item.reference} addPanier={addPanierS} setCLick={setIdClicked} props={props} data={item} />
                                        ) 
                                    }
                            </List.Item>
                            )}
                            loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
                        />  
                    </div>
            </div>
        </Card>
        </>
    );
};

export default withTranslation()(Stocks);
